import React from 'react';
import { Helmet } from 'react-helmet';
import { Container, Left, Title, Desc } from '../../styles/blog.styles'; // Assuming the given CSS is in StyledComponents.js
import { Link } from 'react-router-dom';
import blogs from '../../constant/BlogPosts';

const Blog = () => {
  const blogPosts = blogs;

  return (
    <>
      <Helmet>
        <title>Insights & Tips: Website Development for Startups | TECHSTODGY</title>
        <meta
          name="description"
          content="Explore expert advice, web design trends, and success stories to help your startup thrive online. Stay updated with TECHSTODGY's blog."
        />
      </Helmet>
      <Container>
        <Left>
          <Title>Blog</Title>
          <Desc>
            Stay updated with the latest insights and trends in design, including website design, graphics design, and UI/UX best practices.
          </Desc>
          {blogPosts.map((post, index) => (
            <div key={index} className="blog-post">
              {/* Uncomment below line to use the image */}
              {/* <img src={post.imageUrl} alt={post.title} style={{ width: '100%', height: 'auto', marginBottom: '1rem' }} /> */}
              <h2 style={{ color: 'white', fontFamily: 'Poppins', fontSize: '1.5rem' }}>{post.title}</h2>
              <p
                style={{
                  color: 'rgb(153, 153, 153)',
                  fontFamily: 'Poppins',
                  fontSize: 'small',
                  marginBottom: '1rem',
                }}
              >
                {post.description}
              </p>
              <Link
                to={`/blog/${encodeURIComponent(post.title.replace(/\s+/g, '-').toLowerCase())}`}
                style={{
                  color: 'rgb(104, 240, 160)',
                  background: 'none',
                  border: 'none',
                  fontFamily: 'Poppins',
                  cursor: 'pointer',
                }}
              >
                Read
              </Link>
            </div>
          ))}
        </Left>
      </Container>
    </>
  );
};

export default Blog;
