import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const Container = styled.div`
  margin: 0;
  padding: 7.5vw;
  display: flex;
  justify-content: space-between;
  background-color: #232325;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
    padding: 5vw;
  }
`;

export const LeftSide = styled.div`
  display: flex; 
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  padding: 0;
  width: 60%;

  @media screen and (max-width: 1024px) {
    width: 85%;
  }

  @media screen and (max-width: 768px) {
    width: 90%;
  }
`;

export const SkillsCard = styled.div`
  width: 30vw;
  height: fit-content;
  margin: 0;
  padding: 5%;
  background-color: #2D2E32;
  border-radius: 8px;
  transition: box-shadow 0.5s, margin 0.5s;
  margin-top: 3%;
  z-index: 2;

  &:hover {
    z-index: 1;
    box-shadow: 0px 20px 40px rgb(26, 25, 25);
  }

  @media screen and (max-width: 1024px) {
    width: 50vw;
  }

  @media screen and (max-width: 768px) {
    width: 90%;
  }
`;

export const TitleDescIcon = styled.div`
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    text-align: center;
  }
`;

export const TitleDesc = styled.div`
  margin: 0;
  padding: 0;
`;

export const Title = styled.h1`
  margin: 0;
  padding: 0;
  color: rgb(255, 255, 255);
  font-family: 'Poppins', sans-serif;
  font-size: 1.5rem;
  font-weight: normal;

  @media screen and (max-width: 768px) {
    font-size: 1.2rem;
  }
`;

export const Desc = styled.p`
  margin: 0;
  padding: 0;
  padding-top: 3%;
  color: rgb(153, 153, 153);
  font-family: 'Poppins', sans-serif;
  font-size: 1rem;
  font-weight: normal;

  @media screen and (max-width: 768px) {
    font-size: 0.9rem;
  }
`;

export const Icon = styled(FontAwesomeIcon)`
  margin: 0;
  padding: 0;
  color: rgb(104, 240, 160);
  font-size: 1.5rem;

  @media screen and (max-width: 768px) {
    font-size: 1.2rem;
  }
`;

export const RightSide = styled.div`
  margin: 0;
  padding: 0;
  width: 50%;

  @media screen and (max-width: 1024px) {
    width: 85%;
    text-align: center;
    margin-top: 5%;
  }

  @media screen and (max-width: 768px) {
    width: 90%;
  }
`;

export const DescText = styled.h3`
  margin: 0;
  padding: 0;
  margin-top: 8%;
  font-family: 'Share Tech Mono', monospace;
  color: rgb(153, 153, 153);
  font-weight: normal;
  line-height: 1.5;
  text-align: left;

  @media screen and (max-width: 1024px) {
    text-align: center;
    margin-top: 5%;
    font-size: 1rem;
  }

  @media screen and (max-width: 768px) {
    font-size: 0.9rem;
  }
`;
