import React from "react";
import {Helmet} from "react-helmet"
import {
  Container,
  LeftSide,
  SkillsCard,
  TitleDescIcon,
  TitleDesc,
  Title,
  Desc,
  Icon,
  RightSide,
  DescText,
} from "../../styles/pricing.styles";
import { faCode, faChartLine, faCrown } from "@fortawesome/free-solid-svg-icons";

const Pricing = () => {
  return (
    <>
    <Helmet>
        <title>Affordable Website Development Pricing | TECHSTODGY</title>
        <meta name="description" content="Discover transparent and affordable pricing for TECHSTODGY's website development services. Get tailored solutions that fit your startup’s budget." />
      </Helmet>
      <Container>
      <LeftSide>
        <h1 style={{ color: "white", textAlign: "center" }}>Our Packages</h1>

        <SkillsCard>
          <TitleDescIcon>
            <TitleDesc>
              <Title>Basic</Title>
              <Desc>Perfect for startups looking to establish an online presence.</Desc>
            </TitleDesc>
            <Icon icon={faCode} />
          </TitleDescIcon>
          <Desc>- 5 pages<br />- Basic SEO<br />- 3 months support</Desc>
          <Desc style={{ fontWeight: "bold" }}>Price: $500 - $800</Desc>
        </SkillsCard>

        <SkillsCard>
          <TitleDescIcon>
            <TitleDesc>
              <Title>Standard</Title>
              <Desc>Advanced features for growing businesses.</Desc>
            </TitleDesc>
            <Icon icon={faChartLine} />
          </TitleDescIcon>
          <Desc>- 8-12 pages<br />- Advanced SEO<br />- 6 months support<br />- 1-year hosting</Desc>
          <Desc style={{ fontWeight: "bold" }}>Price: $1,200 - $1,800</Desc>
        </SkillsCard>

        <SkillsCard>
          <TitleDescIcon>
            <TitleDesc>
              <Title>Premium</Title>
              <Desc>Comprehensive package for scaling startups.</Desc>
            </TitleDesc>
            <Icon icon={faCrown} />
          </TitleDescIcon>
          <Desc>- 12+ pages<br />- Full customization<br />- 12 months support<br />- E-commerce included</Desc>
          <Desc style={{ fontWeight: "bold" }}>Price: $2,500 - $3,500</Desc>
        </SkillsCard>
      </LeftSide>

      <RightSide>
        <DescText>
          At our company, we specialize in delivering tailor-made websites to empower startups. With a focus on modern, responsive design and user-friendly interfaces, our packages are crafted to meet the needs of emerging businesses.
        </DescText>
      </RightSide>
    </Container>
    </>
  );
};

export default Pricing;
