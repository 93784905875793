import styled from "styled-components";

// Container for the whole blog page layout
const Container = styled.div`
  display: flex;
  justify-content: space-around;
  padding-top: 4%;
  padding-left: 7.5vw;
  padding-right: 7.5vw;
  background-color: #232325;

  @media screen and (max-width: 1024px) {
    padding: 5%;
    flex-direction: column;
    align-items: center;
  }
`;

// Left section where the main blog content will be displayed
const Left = styled.div`
  padding: 3%;
  width: 100%;

  @media screen and (max-width: 768px) {
    padding: 2%;
  }
`;

// Right section (not used in this case but added for future expansion)
const Right = styled.div`
  padding: 3%;
  width: 100%;

  @media screen and (max-width: 768px) {
    padding: 2%;
  }
`;

// Title for the blog page
const Title = styled.h1`
  margin: 0;
  padding: 0;
  color: white;
  font-family: "Poppins", sans-serif;
  font-size: 2.5rem;
  font-weight: normal;
  text-align: center;

  @media screen and (max-width: 1024px) {
    font-size: 2rem;
  }

  @media screen and (max-width: 768px) {
    font-size: 1.8rem;
  }
`;

// Description text under the main title
const Desc = styled.p`
  margin: 0;
  padding: 0;
  margin-bottom: 16%;
  margin-top: 2%;
  text-align: center;
  color: rgb(153, 153, 153);
  font-family: "Poppins", sans-serif;
  font-size: 1rem;

  @media screen and (max-width: 1024px) {
    font-size: 0.9rem;
  }

  @media screen and (max-width: 768px) {
    font-size: 0.85rem;
    margin-bottom: 10%;
  }
`;

// Blog posts section title (like "All Blogs" or similar)
const AllProjectText = styled.h1`
  width: 100%;
  margin: 0;
  padding: 0;
  padding-top: 2%;
  padding-bottom: 4%;
  text-align: center;
  color: rgb(104, 240, 160);
  font-family: "Poppins", sans-serif;
  text-decoration: underline;
  font-size: 2rem;
  font-weight: normal;
  background-color: #232325;

  @media screen and (max-width: 1024px) {
    font-size: 1.8rem;
  }

  @media screen and (max-width: 768px) {
    font-size: 1.6rem;
  }
`;

// Individual blog post container
const BlogPostContainer = styled.div`
  margin-bottom: 2rem;
  background-color: #333;
  padding: 2rem;
  border-radius: 10px;
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: scale(1.05);
  }

  @media screen and (max-width: 768px) {
    padding: 1.5rem;
  }

  @media screen and (max-width: 480px) {
    padding: 1rem;
  }
`;

// Blog post image styling
const BlogImage = styled.img`
  width: 70%;
  height: auto;
  border-radius: 8px;
  margin-bottom: 1rem;
`;

// Blog post title styling
const BlogTitle = styled.h2`
  color: white;
  font-family: "Poppins", sans-serif;
  font-size: 1.5rem;
  margin-bottom: 0.5rem;

  @media screen and (max-width: 768px) {
    font-size: 1.3rem;
  }

  @media screen and (max-width: 480px) {
    font-size: 1.1rem;
  }
`;

// Blog post description (summary) styling
const BlogDesc = styled.p`
  color: rgb(153, 153, 153);
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  margin-bottom: 1rem;

  @media screen and (max-width: 768px) {
    font-size: 0.9rem;
  }

  @media screen and (max-width: 480px) {
    font-size: 0.85rem;
  }
`;

// Read More button styling
const ReadMoreButton = styled.button`
  color: rgb(104, 240, 160);
  background: none;
  border: none;
  font-family: "Poppins", sans-serif;
  cursor: pointer;
  font-size: 1rem;

  &:hover {
    text-decoration: underline;
  }

  @media screen and (max-width: 768px) {
    font-size: 0.9rem;
  }
`;

// Fullscreen blog content container (for the individual blog page)
const FullScreenBlogContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5%;
  background-color: #232325;

  @media screen and (max-width: 768px) {
    padding: 3%;
  }
`;

// Fullscreen blog title
const FullScreenBlogTitle = styled.h1`
  color: white;
  font-family: "Poppins", sans-serif;
  font-size: 2.5rem;
  margin-bottom: 2rem;

  @media screen and (max-width: 768px) {
    font-size: 2rem;
  }

  @media screen and (max-width: 480px) {
    font-size: 1.8rem;
  }
`;

// Fullscreen blog content area (for detailed blog post content)
const FullScreenBlogContent = styled.div`
  width: 70%;
  color: white;
  font-family: "Poppins", sans-serif;
  font-size: 1.2rem;
  text-align: justify;
  line-height: 1.8;

  @media screen and (max-width: 1024px) {
    width: 85%;
    font-size: 1.1rem;
  }

  @media screen and (max-width: 768px) {
    width: 90%;
    font-size: 1rem;
  }
`;

export {
  Container,
  Left,
  Right,
  Title,
  Desc,
  AllProjectText,
  BlogPostContainer,
  BlogImage,
  BlogTitle,
  BlogDesc,
  ReadMoreButton,
  FullScreenBlogContainer,
  FullScreenBlogTitle,
  FullScreenBlogContent,
};
