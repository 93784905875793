const RecentWorkData = [{
    id: 1,
    title: "Born2Clean",
    techStack: ["ReactJS","NodeJS","ExpressJS","MongoDb"],
    links:{
        demo:"https://born2cleanllcusa.com/"
    }
},
{
    id: 2,
    title: "DuBose",
    techStack: ["ReactJS","NodeJS","ExpressJS"],
    links:{
        demo:"https://thedubose.com/"
    }
},
{
    id: 3,
    title: "Zino Zaccagnini",
    techStack: ["ReactJS","Reactjs"],
    links:{
        demo:"https://zinozee.com/"
    }
},
{
    id: 4,
    title: "MarriageBuilt2Last",
    techStack: ["WIX"],
    links:{
        demo:"https://www.marriagebuilt2last.com/"
    }
}
]


export default RecentWorkData;