import React from 'react'
import { faBezierCurve, faCode, faServer} from '@fortawesome/free-solid-svg-icons';
import {Container, LeftSide, SkillsCard, TitleDescIcon,
  TitleDesc, Title, Desc, Icon, ProjectDone,
  RightSide, Introduce, HelloText, SlogText, DescText} from "../../styles/services.styles";



export default function Services() {
  return (
    <>  
            <Container  id='services'>
                <LeftSide>
                    <SkillsCard>
                        <TitleDescIcon>
                          <TitleDesc>
                              <Title>UI/UX Design</Title>
                              <Desc>We craft innovative and user-centric UI/UX designs tailored to your brand’s vision.</Desc>
                          </TitleDesc>
                              <Icon icon={faBezierCurve}/>
                        </TitleDescIcon>
                        <ProjectDone>3 PROJECTS</ProjectDone>
                    </SkillsCard>

                    <SkillsCard>
                        <TitleDescIcon>
                            <TitleDesc>
                            <Title>Web Development</Title>
                            <Desc>We specialize in building smooth, high-performance websites that bring your ideas to life.</Desc>
                            </TitleDesc>
                                <Icon icon={faCode}/>
                        </TitleDescIcon>
                        <ProjectDone>4 PROJECTS</ProjectDone>
                    </SkillsCard>

                    <SkillsCard>
                        <TitleDescIcon>
                            <TitleDesc>
                            <Title>Graphics</Title>
                            <Desc>We create visually engaging graphics that elevate your brand and captivate your audience.</Desc>
                            </TitleDesc>
                                <Icon icon={faServer}/>
                        </TitleDescIcon>
                        <ProjectDone>4 PROJECTS</ProjectDone>
                    </SkillsCard>
                </LeftSide>


                <RightSide>
                    <Introduce>introduce</Introduce>
                    <HelloText>Hello! We Are TECHSTODGY</HelloText>
                    <SlogText>Every Great Design Begins with<br/>an Even Better Story</SlogText>
                    <DescText>
                    At TECHSTODGY, we transform your ideas into reality with innovative web solutions. As a full-service development team, we specialize in creating seamless front-end and back-end web applications that are scalable, functional, and visually stunning.

In addition to web development, we also offer expert graphic design services, ensuring your brand stands out with impactful and creative visuals. Whether it’s a website, app, or digital content, we’re committed to delivering designs that are as intuitive as they are beautiful.

At TECHSTODGY, we are constantly improving, tackling every challenge with confidence and curiosity. Let’s create something extraordinary together!
                    </DescText>
                </RightSide>
            </Container>
    </>
  )
}

