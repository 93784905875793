import React from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import { Left, FullScreenBlogContainer, FullScreenBlogTitle, FullScreenBlogContent, BlogImage } from '../../styles/blog.styles';
import blogPosts from '../../constant/BlogPosts';

const BlogFullScreen = () => {
  const { title } = useParams(); // Get the title from the URL
  const decodedTitle = decodeURIComponent(title.replace(/-/g, ' ')); // Decode and format the title
  const post = blogPosts.find((p) => p.title.toLowerCase() === decodedTitle.toLowerCase()); // Find the blog post

  if (!post) {
    return <p>Blog post not found!</p>;
  }

  return (
    <>
      <Helmet>
        <title>{post.title}</title>
        <meta name="description" content={post.content} />
      </Helmet>
      <FullScreenBlogContainer>
        <Left>
          <FullScreenBlogTitle>{post.title}</FullScreenBlogTitle>
          {/* Uncomment below line to use the image */}
          <BlogImage src={"https://drive.google.com/uc?id=h1XVt7wY439wz-OM6xJQ_PnxmbwzmdEx7r"} alt={post.title} />
          <FullScreenBlogContent dangerouslySetInnerHTML={{ __html: post.content }}></FullScreenBlogContent>
        </Left>
      </FullScreenBlogContainer>
    </>
  );
};

export default BlogFullScreen;
