import React from 'react'
import {Helmet} from "react-helmet"
import myPhoto from "../../assests/images/my-pic.webp";
import { faNodeJs, faReact} from '@fortawesome/free-brands-svg-icons';
import { ChatWithMe, Circle, Container, Dot, Experiences, HomePage, LeftSide, MyDesc, MyPhoto, MySlogan, MyTitle, NodeJs, NodeJsIcon, ReactJs, ReactJsIcon, RightSide, WorksAndExp, Works, Years, YearsDesc } from '../../styles/home.styles';
import Services from "../services/Services";
import Contact from "../contact/Contact"

export default function Home() {
  return (
    <>
      <Helmet>
        <title>Affordable Website Development for Startups | TECHSTODGY</title>
        <link rel="preload" href={myPhoto} as="image" type="image/webp"></link>
        <meta name="description" content="Launch your startup with a modern, responsive, and SEO-friendly website from TECHSTODGY. Affordable pricing, fast delivery, and tailored solutions for small businesses." />
      </Helmet>
        <HomePage id='home'>
          <Container>
            <LeftSide>
              <MyTitle>We will bring your ideas to life!</MyTitle>
              <MySlogan>Your creative <br/>web development partner.</MySlogan>
              <MyDesc>At TECTSTODGY, we specialize in delivering exceptional web development solutions tailored to your unique needs. Our team of expert developers is passionate about crafting stunning, functional, and scalable applications that stand out.</MyDesc>
              <ChatWithMe href="mailto:techstodgy@gmail.com" target={"_blank"}>Let's Chat Today!</ChatWithMe>
              <WorksAndExp>
                <Experiences>
                  <Years>2</Years>
                  <YearsDesc>Experience<br/>That Counts</YearsDesc>
                </Experiences>
                <Works>
                  <Years>4</Years>
                  <YearsDesc>Proven<br/>Success</YearsDesc>
                </Works>
              </WorksAndExp>  
            </LeftSide>
            <RightSide>
              <Circle/>
              <NodeJs>
                <NodeJsIcon icon={faNodeJs}/>
              </NodeJs>
              <ReactJs>
                <ReactJsIcon icon={faReact}/>
              </ReactJs>
              <Dot/>
              <MyPhoto src={myPhoto} alt="myphoto"/>
            </RightSide>
          </Container>
        </HomePage>

        <Services/>

        <Contact/>
    </>
  )
}
