import Home from "./pages/home/Home";
import Projects from "./pages/projects/Projects";
import Blog from "./pages/blog/Blog"
import Pricing from "./pages/pricing/Pricing"
import BlogFullScreen from "./pages/bolg_fullscreen/BlogFullScreen";
import "./App.css"
import {Routes, Route} from "react-router-dom";
import { Header } from "./core-ui";
import AnalyticsTracker from "./AnalyticsTracker/AnalyticsTracker";

function App() {
  return (
    <>
        <AnalyticsTracker />
        <Header/>
        <Routes>
          <Route path="/" element={<Home />}/>
          <Route path="/projects" element={<Projects />}/>
          <Route path="/pricing" element={<Pricing />}/>
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/:title" element={<BlogFullScreen />} />
        </Routes>
    </>
  );
}

export default App;
