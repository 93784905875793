import React from 'react'
import { Helmet } from 'react-helmet';
import { WorkCard } from '../../core-ui'
import RecentWorkData from '../../constant/RecentWrokData';

import {Container, Left, Right, Title, Desc} from "../../styles/works.styles";

export default function Projects() {
  return (
    <>
        <Helmet>
        <title>Our Web Development Projects | TECHSTODGY</title>
        <meta name="description" content="Explore our portfolio of successful website development projects for startups and small businesses. See how TECHSTODGY brings ideas to life with custom, responsive designs." />
      </Helmet>
        <Container id='works'>
          <Left>
            <Title>Latest Projects</Title>
            <Desc>Perfect solutions for digital experience</Desc>
            <WorkCard title={RecentWorkData[0].title} techStack={RecentWorkData[0].techStack} image={require("../../assests/images/one.webp")} links={RecentWorkData[0].links}/>
            <WorkCard title={RecentWorkData[1].title} techStack={RecentWorkData[1].techStack}  image={require("../../assests/images/two.webp")} links={RecentWorkData[1].links}/>
          </Left>

          <Right>
            <WorkCard title={RecentWorkData[2].title} techStack={RecentWorkData[2].techStack} image={require("../../assests/images/three.webp")} links={RecentWorkData[2].links}/>
            <WorkCard title={RecentWorkData[3].title} techStack={RecentWorkData[3].techStack} image={require("../../assests/images/four.webp")} links={RecentWorkData[3].links}/>
          </Right>
         </Container> 
         {/* <AllProjectText>ALL PROJECTS</AllProjectText> */}
    </>
  )
}
